import React from "react";
import styled  from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import DropWrapper from "../../components/UI/DropWrapper";
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import { Heading, Paragraph } from "../../components/UI/Typography";
import DropRtlSvg from "../../images/bkg_drop_rtl.svg";
import DropUp from '../../images/case_studies/ploom/DropUp.svg';
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import Graph3 from '../../components/UI/Lottie/Colleghi.json';
import { useInView } from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import VideoJTI from '../../images/case_studies/ploom/Banner Ploom.mp4';
import SEO from "../../components/SEO";
import LutherLogo from '../../images/case_studies/enel/luther_logo.svg';
import {Fade} from 'react-reveal';
import useWindowSize from "../../hooks/useWindowSize";
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatSection = styled.div`
padding-top:32px;
h1{
font-weight: bold;
font-size: 80px;
letter-spacing: 2.9px;
color:#1D0F41;
}
h2{
font-weight: bold;
font-size: min(max(32px,2.8vw),50px);
letter-spacing: 1.82px;
color: #A06BDA;
}
p{
    font-weight:bold;
    font-size:18px
    letter-spacing:0.39px;
    color: #A06BDA;

}
`

const StatsDiv = styled.div`
margin-top: 32px;
display:flex;
h3{
color:#1D0F41;
font-weight:700;
font-style:normal;
font-size:32px;
margin:0px;
}
p{
    color:#A06BDA;
    font-size:18px;
    font-weight:700;
}
`

const CompanyStats = () => {
    return <StatsDiv>
        <div>
            <h3>
                25,1%
        </h3>
            <p>
                <FormattedMessage id={'jti.stats_1_msg'} />
            </p>
        </div>
        <div className="ml-4">
            <h3>
                110
        </h3>
            <p>
                <FormattedMessage id={'jti.stats_2_msg'} />
            </p>
        </div>
    </StatsDiv>
}

const JtiCaseStudy = ({intl}) => {
    const [graphView2, inViewG2] = useInView();
    const keywords = intl.formatMessage({id:'jti.meta_keywords'}).split(',') || [];
    const {width: pageWidth} = useWindowSize();

    return (
        <>
            <SEO title={intl.formatMessage({id: 'jti.meta_title'})} description={intl.formatMessage({id: 'jti.meta_description'})} keywords={keywords} lang={intl.locale}/>
            <HeroCaseStudy hasPartner={true} partnerLogo={LutherLogo} heroImage={'case_studies/ploom/header ploom.png'}
                           title={'jti.title'} stats={<CompanyStats />} subtitle1={'jti.subtitle'} subtitle2={'jti.subtitle_2'}
                           intl={intl} color={'linear-gradient(23.36deg, #A06BDA  16.59%, #25234C 87.97%)'}
                           categories={['portfolio.filters.design_dev']}/>
            <div className="section-padding">
                <DropWrapper svgfile={DropRtlSvg} position="left" top="140%"/>
                <DropBg src={DropUp} bottom={'5%'} left={'22%'} className="d-none d-lg-block" />
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'enel.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className={"col-lg-5 col-12"}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"jti.client_title"}/>
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"jti.client_1"}/>
                                    <strong> <FormattedMessage id={"jti.client_1_strong"}/></strong>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className={"col-lg-6 col-12 offset-md-1"}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"jti.client_needs"}/>
                                </Heading>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"jti.client_needs_1"}/>
                                </Paragraph>
                                <StatSection>
                                    <h1>
                                        499,37
                                    </h1>
                                    <h2>
                                        <FormattedMessage id={'acea.sales_1'}/>

                                    </h2>
                                    <p>
                                        <FormattedMessage id={'acea.sales_2'}/>
                                    </p>
                                </StatSection>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <FullWidthImage
                dangerouslySetInnerHTML={{
                    __html: `
                        <video
                            style="width:100%;height:100%;object-fit:cover"
                            loop
                            muted
                            autoplay
                            playsinline
                            webkit-playsinline
                            src="${VideoJTI}"
                        >
                    `
                }}
                imageWidth={1920}
                imageHeight={1080}
            />
            <div className="section-padding">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block pl-5">
                                <SectionTitle number={'02'} text={'jti.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"jti.service_big_title"} />
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"jti.services_txt_1"} />
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={400}>
                            <div className="col-md-7 col-sm-12">
                                <SectionTitle number={'03'} text={'jti.workflow_title'}/>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"jti.solutions_big_title"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"jti.solutions_txt_1"}/>
                                </Paragraph>
                                <Paragraph className="pt-2" margin={'0px'}>
                                    <FormattedMessage id={"jti.solutions_txt_2"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-4 col-sm-12 offset-1">
                                <Image filename={'case_studies/ploom/ploom-responsive.jpg'} className="section-image"
                                       alt={'Ploom'} style={{width: pageWidth < 768 ? '100%' : '180%'}}/>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <Fade bottom delay={400}>
                            <div className="col-md-6 col-sm-12 order-last order-md-first">
                                <Image filename={'case_studies/ploom/ploo3d.jpg'} className="section-image" alt={'Ploom'}/>
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-md-6 col-12 col-sm-12 order-first order-md-last">
                                <Paragraph className="pt-2 pr-md-4">
                                    <FormattedMessage id={"jti.solutions_txt_3"}/>
                                </Paragraph>
                                <Paragraph className="pt-2 pr-md-5">
                                    <FormattedMessage id={"jti.solutions_txt_4"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Results
                subtitle="jti.dark_section_paragraph"
                testimonialQuote="jti.dark_section_quote"
                testimonialImg="carlo_angeletti.jpg"
                testimonialName="jti.dark_section_testimonial_name"
                testimonialRole="jti.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView2}>
                        <LottieController
                            lottieJSON={Graph3}
                            inView={inViewG2}
                            height="100px"
                            width="116px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG2}
                            valueFrom={0}
                            valueTo={35}
                            measureUnit="+"
                            headingStyle={{fontWeight: 500}}
                            label="jti.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="jti.next_success_case_title"
                desc="jti.next_success_case_description"
                link="/case-study/translated"
                intl={intl}
            />
        </>
    );
};

const customProps = {
    localeKey: "jti", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(JtiCaseStudy));
